import {findItemsByMapId, objArrMapObj} from "@/untils/otherEvent"

/** 送评类型
 * A  放心购（平台订单，卖家包评级）
 * B 自主送评（平台订单，卖家不包评级，买家个人承担费用直接转评级）
 * C 个人延长收货送评（平台订单，收到货后，自费来送评）
 * D 个人送评（非平台订单的送评）
 * */
export const dqCheckPayTypeList = [
    {name: 'A类\n放心购', value: 'A', desc: '平台订单，卖家包评级'},
    {name: 'B类\n自主送评', value: 'B', desc: '平台订单，卖家不包评级，买家个人承担费用直接转评级'},
    {name: 'C类\n个人延长收货送评', value: 'C', desc: '平台订单，收到货后，自费来送评'},
    {name: 'D类\n个人送评', value: 'D', desc: '非平台订单的送评'},
]
export const dqCheckPayTypeListByAD = [
    {name: 'ABC类送评', value: 'ABC'},
    {name: 'D类送评', value: 'D'},
]

export const dqCheckPayClassType = {
    A: 'color-green',
    B: 'color-blue',
    C: 'color-cadetblue',
    D: 'color-orange'
}

/** 质检结果类型 */
export const dqCheckResultTypeList = [
    {name: '清理', value: 1, mapId: ['C']},
    {name: '货不对板', value: 3, mapId: ['C']},
    {name: '虚假发货', value: 5, mapId: ['A', 'B']},
    {name: '色差严重', value: 15, mapId: ['A', 'B']},
]

export const dqCheckResultClassType = {
    5: 'color-gray',
    10: 'color-red',
    15: 'color-orange',
}

/** 质检状态 */
export const dqCheckStatusTypeList = [
    {name: '正常', value: 5, mapId: ['D', 'A', 'B', 'C']},
    {name: '异常', value: 10, mapId: ['A', 'B', 'C']}
]
/** 质检状态类 */
export const dqCheckStatusClassType = {
    5: 'color-green',
    10: 'color-red'
}

/** 是否标版 */
export const dqCheckVersionStatusTypeList = [
    {name: '标版', value: 1},
    {name: '不标版', value: 0}
]

/** 是否标版状态类 */
export const dqCheckVersionStatusClassType = {
    1: 'color-green',
    2: 'color-red'
}

/** 买家是否需要 */
export const dqCheckResultAfterByBuyerTypeList = [
    {name: '未选择', value: 0},
    {name: '需要', value: 1},
    {name: '不需要', value: 2}
]
export const dqCheckResultAfterByBuyerClassType = {
    1: 'color-green',
    2: 'color-red'
}

/** 在途 */
export const dqArriveTypeList = [
    {name: '已收货', value: 1},
    {name: '未收货', value: -1},
]
export const dqArriveClassType = new Map([
    [-1, 'color-red'],
    [1, 'color-green']
])

/** 鉴定后买家选择 0未选择  1需要并保价发货  2需要并不保价发货  3不需要*/
export const buyerSelAfterRatingTypeList = [
    {name: '未选择', value: 0},
    {name: '需要并保价发货', value: 1},
    {name: '需要并不保价发货', value: 2},
    {name: '不需要', value: 3},
]
/** 鉴定后买家选择对应的色值 */
export const buyerSelAfterRatingClassType = {
    0: 'color-gray',
    1: 'color-green',
    2: 'color-orange',
    3: 'color-red'
}

/** 退货时保价选择 0 未选择  1保价   2不保价   3不要了(买家选择不要 或者 鉴定假)*/
export const sellerSelReturnAfterBuyerTypeList = [
    {name: '未选择', value: 0},
    {name: '保价', value: 1},
    {name: '不保价', value: 2},
    {name: '不要了(买家选择不要 或者 鉴定假)', value: 3},
]

/** 鉴定平台 */
export const dqCheckQualityType = [
    {name: '平台初次鉴定', value: 10},
    {name: '评级公司鉴定', value: 20},
    {name: '平台终极鉴定', value: 30},
]

/** 鉴定结果 */
export const RATE_RESULT_TYPE_LIST = [
    { name: '正常', value: 5 },
    { name: '瑕疵', value: 20 },
    { name: '备注', value: 35 },
    { name: '描述不符', value: 40 },
    { name: '存疑', value: 25 },
    { name: '赝品', value: 30 },
]

/** 鉴定结果对应的色值 */
export const RATE_RESULT_CLASS_TYPE_LIST = {
    5: 'color-green',
    20: 'color-orange',
    35: 'color-black',
    40: 'color-cadetblue',
    25: 'color-gray',
    30: 'color-red'
}

/** D类送评状态 5待发货    10待评级    15评级中   20已评级待确认   25鉴定确认(评级完成)*/
export const D_TYPE_RATE_STATUS_TYPE_LIST = [
    { name: '已取消', value: -1 },
    { name: '待发货', value: 5 },
    { name: '待评级', value: 10 },
    { name: '评级中', value: 15 },
    { name: '已评级待确认', value: 20 },
    { name: '评级完成', value: 25 },
]

/** 鉴定结果对应的色值 */
export const D_TYPE_RATE_STATUS_CLASS_TYPE_LIST = new Map([
    [-1, 'color-gray'],
    [5, 'color-black'],
    [10, 'color-red'],
    [15, 'color-orange'],
    [20, 'color-cadetblue'],
    [25, 'color-green'],
])

/** D类评级费用 */
export const D_TYPE_RATE_PRICE_TYPE = {
    A: 50,
    B: 100,
    C: 150,
    D: 300,
    E: 1000
}
/** D类标版费用 */
export const D_TYPE_VERSION_PRICE_TYPE = {
    A: 10,
    B: 10,
    C: 10,
    D: 10,
    E: 10
}

/** 支付状态 0未支付，2已支付，4已扣除，5可退回，6已退回*/
export const D_TYPE_PAY_STATUS_TYPE_LIST = [
    { name: '未支付', value: 0 },
    { name: '已支付', value: 2 },
    { name: '已扣除', value: 4 },
    { name: '可退回', value: 5 },
    { name: '已退回', value: 6 },
]
/** D类送评-文案 */
export const dTypePayStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', D_TYPE_PAY_STATUS_TYPE_LIST)[value]
}

/** 支付状态对应的类 */
export const D_TYPE_PAY_STATUS_CLASS_TYPE ={
    0: 'color-red',
    2: 'color-green',
    4: 'color-orange',
    5: 'color-blue',
    6: 'color-gray'
}

/** 评级管理-评级状态 */
export const RATE_STATUS_TYPE_LIST = [
    {name: '已取消', value: -1},
    {name: '待发货', value: 0},
    {name: '等待公司收货', value: 5},
    {name: '等待质检', value: 10},
    {name: '质检非正常-等待买家选择', value: 15},
    {name: '等待评级公司收货', value: 20},
    {name: '等待评级公司鉴定', value: 25},
    {name: '等待平台确认鉴定', value: 30},
    {name: '鉴定非正常等待买家选择', value: 35},
    {name: '鉴定非正常买家已选择-等待卖家选择', value: 40},
    {name: '已鉴定确认买家已选择(未打印未发货)', value: '45NotExpress'},
    {name: '已鉴定确认买家已选择(已打印未发货)', value: '45Express'},
    {name: '已鉴定确认卖家已选择(未打印未发货)', value: '50NotExpress'},
    {name: '已鉴定确认卖家已选择(已打印未发货)', value: '50Express'},
    {name: '已鉴定确认卖家已选择(已发货)', value: '60Express'},
]
export const RATE_STATUS_CLASS_TYPE = {
    0: 'color-black',
    5: 'color-gray',
    10: 'color-green',
    15: 'color-blue',
    20: 'color-green',
    25: 'color-blue',
    30: 'color-blue',
    35: 'color-blue',
    40: 'color-red',
    45: 'color-green',
    50: 'color-blue',
    '45NotExpress': 'color-black',
    '45Express': 'color-blue',
    '50NotExpress': 'color-black',
    '50Express': 'color-blue',
    '60Express': 'color-green',
}
/** 鉴定类型-10 平台质检鉴定   15 评级公司收货  20 评级公司鉴定   30 平台终极鉴定  40 评级发货 */
export const RATE_STATUS_QUALITY_TYPE_LIST = [
    {name: '平台质检鉴定', value: 10},
    {name: '评级公司收货', value: 15},
    {name: '评级公司完成鉴定关联钱币评级信息', value: 20},
    {name: '平台终极鉴定', value: 30},
    {name: '评级发货', value: 40},
]
export const rateStatusQualityTypeMapText = (value) => {
    return objArrMapObj('value', 'name', RATE_STATUS_QUALITY_TYPE_LIST)[value]
}
/** 质检状态-reviewStatus
 * 质检状态  5正常  10不正常\n鉴定结果  5正常  20瑕疵  25存疑  30赝品
 * 平台鉴定状态  5正常  20瑕疵  25存疑  30赝品  （瑕疵 存疑 需要买家选择要或不要）, */
export const RATE_STATUS_REVIEW_STATUS_TYPE_LIST =[
    {name: '正常', value: 5},
    {name: '不正常', value: 10},
    {name: '瑕疵', value: 20},
    {name: '存疑', value: 25},
    {name: '赝品', value: 30},
    {name: '备注', value: 35 },
    {name: '描述不符', value: 40 },
]
export const rateStatusReviewStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', RATE_STATUS_REVIEW_STATUS_TYPE_LIST)[value]
}

/** 评级状态-  0 正常  1 赝品  2 存疑*/
export const rateServiceResultStatusTypeList = {
    0: '正常',
    1: '赝品',
    2: '存疑'
}
export const rateServiceResultStatusClassType = {
    0: 'color-green',
    1: 'color-red',
    2: 'color-gray'
}

/** 确认状态 */
export const CONFIRM_TYPE_LIST = [
    { name: '未确认', value: 0 },
    { name: '已确认', value: 1 },
]
/** 确认状态色值 */
export const CONFIRM_CLASS_TYPE = {
    0: 'color-red',
    1: 'color-green'
}

/** 买卖双方选择是否选择了需要或者待选择  0全部  1买家未选择   2卖家未选择   3已选择*/
export const SELECTED_BY_BUYER_SELLER_TYPE_LIST = [
    { name: '全部', value: 0 },
    { name: '买家待选择', value: 1 },
    { name: '卖家待选择', value: 2 },
    { name: '已选择', value: 3 },
]

/** D类是否对版 */
export const D_TYPE_VERSION_STATUS_TYPE_LIST = [
    { name: '不需要', value: 0 },
    { name: '需要', value: 1 },
]
export const dTypeVersionStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', D_TYPE_VERSION_STATUS_TYPE_LIST)[value]
}

/** 卖家是否补偿买家 */
export const SELLER_COMPENSATE_BUYER_PRICE_STATUS_TYPE_LIST = [
    {name: '补偿', value: 1},
    {name: '不补偿', value: 0},
]
/** 卖家是是否扣除保证金a */
export const DEDUCT_ALL_BOND_PRICE_BY_SELLER_STATUS_TYPE_LIST = [
    {name: '扣除1000', value: 1},
    {name: '不扣', value: 0},
]

/** 瑕疵状态二级分类 */
export const REMARK_TYPE_LIST = [
    {name: '掩盖瑕疵', value: 25},
]
/** 描述不符 */
export const DESC_NOT_MATCH_LIST = [
    {name: '描述不符', value: 20},
]
/** 有备注 */
export const HAS_NOTES_LIST = [
    {name: '有备注', value: 35},
]

/** 快捷文案 */
export const quickInputDescTextList = [
    '评级结果为存疑，买家可无责取消，买家如果选择要，此订单将完成，此订单将不再进行任何售后',
    '拍品鉴定为赝品，订单取消，钱币退回给卖家。卖家如果对评级结果有异议，可联系平台客服进行咨询',
    // '评级结果备注显示钱币：加刀清理，卖家没有说明，属于掩盖瑕疵，买家可无责取消，买家如果选择要，订单完成，此订单将不再处理售后',
    // '评级结果备注显示钱币：有裂，卖家在描述中说明清楚，不属于隐瞒瑕疵，请买家选择收货方式',
    // '评级结果尺寸和卖家描述尺寸误差大于0.3，买家可无责取消，买家如果选择要，订单完成，此订单将不再处理售后',
    // '评级结果显示版别与卖家描述不符，买家可无责取消，买家如果选择要，订单完成，此订单将不再处理售后'
]

/** D类送评明细支付状态 2支付，6.退回，4扣除，*/
export const dTypeRatingLinePayTypeList = [
    { name: '已支付', value: 2 },
    { name: '已扣除', value: 4 },
    { name: '已退回', value: 6 },
]
/** 以上对应样式类 */
export const dTypeRatingLinePayTypeClassType = {
    2: 'color-green',
    4: 'color-red',
    6: 'color-gray'
}

export const dTypeRatingLinePayTypeMapText = (value) => {
    return objArrMapObj('value', 'name', dTypeRatingLinePayTypeList)[value]
}

export const rateConfirmTypeMapText = (value) => {
    return objArrMapObj('value', 'name', CONFIRM_TYPE_LIST)[value]
}
export const rateStatusTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', RATE_STATUS_TYPE_LIST)[value]
}
export const buyerSelAfterRatingTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', buyerSelAfterRatingTypeList)[value]
}
export const sellerSelReturnAfterBuyerTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', sellerSelReturnAfterBuyerTypeList)[value]
}

export const rateServicePayTypeMapText = (value) => {
    return objArrMapObj('value', 'name', dqCheckPayTypeList)[value]
}
export const rateServicePayTypeMapTextByDesc = (value) => {
    return objArrMapObj('value', 'desc', dqCheckPayTypeList)[value]
}

export const rateResultTypeMapText = (value) => {
    return objArrMapObj('value', 'name', RATE_RESULT_TYPE_LIST)[value]
}
// D类送评状态
export const dTypeRateStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', D_TYPE_RATE_STATUS_TYPE_LIST)[value]
}

export const computedResultType = (value) => {
    return objArrMapObj('value', 'name', dqCheckResultTypeList)[value]
}

export const computedStatusType = (value) => {
    return objArrMapObj('value', 'name', dqCheckStatusTypeList)[value]
}
export const computedVersionStatusType = (value) => {
    return objArrMapObj('value', 'name', dqCheckVersionStatusTypeList)[value]
}

export const computedResultAfterByBuyerType = (value) => {
    return objArrMapObj('value', 'name', dqCheckResultAfterByBuyerTypeList)[value]
}

export const computedArriveClassType = (value) => {
    return objArrMapObj('value', 'name', dqArriveTypeList)[value]
}

export const findCheckResultTypeList = (key) => {
    return findItemsByMapId(dqCheckResultTypeList, key)
}

export const findCheckStatusTypeList = (key) => {
    return findItemsByMapId(dqCheckStatusTypeList, key)
}